




















































import {
  computed,
  defineComponent,
  nextTick,
  reactive,
  ref,
} from "@vue/composition-api";
import { whenever } from "@vueuse/shared";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  emits: ["update"],
  setup(props, { emit, root }) {
    const axiosInstance = computed(
      () => root.$store.getters["api/getInstance"] as AxiosInstance
    );

    const isOpen = ref(false);

    const open = () => (isOpen.value = true);
    const close = () => (isOpen.value = false);
    const toggle = () => (isOpen.value ? close() : open());

    const form = ref();
    const isFormValid = ref(true);

    const loading = ref(false);
    const error = ref<string | null>(null);

    const model = reactive<{
      description: string | null;
      status: "in_progress" | "completed" | null;
    }>({
      description: null,
      status: null,
    });

    const rules: {
      description?: ((v: string | null) => boolean | string)[];
      attachments?: ((v: File[]) => boolean | string)[];
    } = {
      description: [(v: string | null) => !!v || "Opis jest wymagany"],
      attachments: [],
    };

    const resetModel = () => {
      model.description = null;
      model.status = null;
    };

    const fetchData = async () => {
      error.value = null;
      resetModel();

      loading.value = true;
      axiosInstance.value
        .get(`damage/${props.id}`)
        .then(({ data }) => {
          model.description = data.description;
          model.status = data.status;
        })
        .catch(() => {
          error.value = "Nie udało się załadować danych";
        })
        .finally(() => {
          loading.value = false;
        });
    };

    whenever(isOpen, fetchData);

    const onSubmit = async () => {
      error.value = null;
      await form.value?.validate();

      if (isFormValid.value) {
        loading.value = true;

        const data = {
          description: model.description ?? undefined,
          status: model.status ?? undefined,
        };

        axiosInstance.value
          .put(`damage/${props.id}`, data)
          .then(() => {
            close();
            emit("update");
            nextTick(() => {
              resetModel();
            });
          })
          .catch(() => {
            error.value = "Nie udalo się edytować szkody";
          })
          .finally(() => {
            loading.value = false;
          });
      }
    };

    const statuses = [
      { text: "W trakcie", value: "in_progress", color: "primary" },
      { text: "Zakończone", value: "completed", color: "success" },
    ];

    const getStatusColor = (status: string) =>
      statuses.find((s) => s.value === status)?.color ?? undefined;

    const getStatusText = (status: string) =>
      statuses.find((s) => s.value === status)?.text ?? undefined;

    return {
      isOpen,
      open,
      close,
      toggle,
      form,
      isFormValid,
      loading,
      error,
      model,
      rules,
      onSubmit,
      statuses,
      getStatusColor,
      getStatusText,
    };
  },
});
